import React, { Component, Suspense } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import * as Sentry from "@sentry/react";
import moment from 'moment';
import { MainContext } from './MainContext';
// import withrouter from './../helper/withrouter';
import SplashScreen from './../components/SplashScreen/SplashScreen';
import theData from './../data.json';
import PayApple from './../assets/img/stock-payment/applepay.jpg';
import PayGoogle from './../assets/img/stock-payment/googlepay.jpg';
import PayMaster from './../assets/img/stock-payment/mastercard.png';
import PayOcbc from './../assets/img/stock-payment/ocbc.jpg';
import PayOvo from './../assets/img/stock-payment/ovo.png';
import PayRazer from './../assets/img/stock-payment/razer.png';
import PayStripe from './../assets/img/stock-payment/stripe.jpg';
import PayVisa from './../assets/img/stock-payment/visa.png';
import PayCash from './../assets/img/stock-payment/cash.png';

import {
    authenticate,
    authenticated,
    getToken,
    getQrStatus,
    getQrType,
    getQrTimeStamp,
    getCurrency,
    getGuest,
    getTable,
    getName,
    getMail,
    getAddress,
    getPhone,
    getChargeOpt,
    getImg,
    tokenExpired
} from "../api/services/auth"
import { listProducts } from "../api/services/products"

const Home = React.lazy(() => {
    return new Promise(resolve => {
        setTimeout(() => resolve(import('./Home/Home')), 250);
    });
}),
    // Home = React.lazy(() => import('./Home/Home')),
    Search = React.lazy(() => import('./Search/Search')),
    Combo = React.lazy(() => import('./Combo/Combo')),
    Cart = React.lazy(() => import('./Cart/Cart')),
    Result = React.lazy(() => import('./Result/Result')),
    Order = React.lazy(() => import('./Order/Order')),
    OrderDetail = React.lazy(() => import('./OrderDetail/OrderDetail')),
    Error = React.lazy(() => import('./Error/Error'));

function FallbackComponent() {
    return <div>An error has occurred</div>;
}

const myFallback = <FallbackComponent />;
class App extends Component {

    static contextType = MainContext;

    constructor() {
        super();

        this.state = {
            // menuList: JSON.parse(JSON.stringify(theData)),
            menuList: [],
            addedList: [],
            checkedOut: [],
            totalQty: 0,
            totalPrice: 0,
            infoPhone: '',
            paymentMethod: '',
            paymentStatus: false,
            priceSum: '',
            grandTotal: 0,
            qrcode: null,
            setOrder: this.setOrder,
            setToken: this.setOrder,
            setCode: this.setCode,
            setBackTo: this.setBackTo,
            setOrderNumber: this.setOrderNumber,
            setQueueNumber: this.setQueueNumber,
            setOrderCount: this.setOrderCount,
            setOrderType: this.setOrderType,
            apiload: false,
            landingPage: true,
            error: {
                error: false,
                title: '',
                content: '',
            },
            appCurrency: 'RM ',
            restoInfo: {
                name: 'Spurr Cafe',
                address: 'Petaling Jaya, Selangor',
                email: 'cs@spurrcafe.com',
                phone: '+6285987654321',
                table: '-',
                qrType: '-',
                guest: 3,
                headerImage: '',
            },
            appTax: [],
            token: null,
        }

        // this.appCurrency = 'RM ';
        this.appRequest = true;
        // this.restoInfo = {
        //   name: 'Spurr Cafe',
        //   address: 'Petaling Jaya, Selangor',
        //   email: 'cs@spurrcafe.com',
        //   phone: '+6285987654321',
        //   table: 123,
        //   guest: 3
        // };
        // this.appTax = [
        //     { name: 'SVC Tax', value: 10, type: 'percentage', include: false },
        //     { name: 'GST', value: 5, type: 'percentage', include: true },
        //     { name: 'Order Fee', value: 2, type: 'amount', include: false }
        // ];
        this.appPromo = [{
            promocode: 'SPURRTOGOPRO',
            value: 20,
            type: 'percentage'
        }, {
            promocode: 'SPURRTOGO2022',
            value: 2000,
            type: 'amount'
        }];
        this.countryCode = [
            { value: '+62', label: 'Indonesia (+62)' },
            { value: '+60', label: 'Malaysia (+60)' },
            { value: '+65', label: 'Singapore (+65)' },
        ];
        this.defaultCode = this.countryCode[1];
        this.paymentMethod = [
            {
                name: 'Cash',
                img: PayCash,
                status: true
            }
            // {
            //     name: 'Apple Pay',
            //     img: PayApple,
            //     status: true
            // }, {
            //     name: 'Google Pay',
            //     img: PayGoogle,
            //     status: false
            // }, {
            //     name: 'Master Card',
            //     img: PayMaster,
            //     status: true
            // }, {
            //     name: 'OCBC',
            //     img: PayOcbc,
            //     status: false
            // }, {
            //     name: 'OVO',
            //     img: PayOvo,
            //     status: true
            // }, {
            //     name: 'Razer',
            //     img: PayRazer,
            //     status: false
            // }, {
            //     name: 'Stripe',
            //     img: PayStripe,
            //     status: true
            // }, {
            //     name: 'Visa Card',
            //     img: PayVisa,
            //     status: false
            // }
        ];

        this.addItem = this.addItem.bind(this);
        this.addPayment = this.addPayment.bind(this);
        this.addOrderType = this.addOrderType.bind(this);
        this.setFromBack = this.setFromBack.bind(this);
    }

    //This is the method to set the context data.
    setOrder = (order) => {
        console.log('app.setOrder', order)
        this.setState({ order });
    };

    setToken = (t) => {
        console.log('app.setToken', t)
        this.setState({ token: t });
    };

    //This is the method to set the context data.
    setCode = (code) => {
        this.setState({ code: code });
    };

    //This is the method to set the context data.
    setBackTo = (url) => {
        this.setState({ backTo: url });
    };

    //This is the method to set the context data.
    setOrderNumber = (number) => {
        this.setState({ orderNumber: number });
    };

    setQueueNumber = (number) => {
        this.setState({ queueNumber: number });
    };

    //This is the method to set the context data.
    setOrderCount = (total) => {
        this.setState({ orderCount: total });
    };

    setOrderType = (type) => {
        this.setState({ orderType: type });
    };

    // componentWillMount() {
    //   console.log('app.componentWillMount')
    // }

    async componentDidMount() {
        console.log('app.componentDidMount')
        // this.setState({ menuList: JSON.parse(JSON.stringify(theData)) });

        const code = (new URLSearchParams(window.location.search)).get("code")

        if (!code) {
            // @todo : change with a nice UI
            alert('QrCode not provided')
            return false;
            // this.setState({
            //   error: {
            //     error: true,
            //     title: 'QR Code not provided',
            //     content: 'Please scan a valid qr code',
            //   }
            // })
        } else {

            const wasAuthenticated = await authenticated(code)
            console.log('wasAuthenticated', wasAuthenticated)

            if (!wasAuthenticated) {
                this.setState({
                    apiload: true,
                });

                // const auth = await authenticate(code)
                const { success, token } = await authenticate(code)
                if (!success) {
                    // @todo : change with a nice UI
                    alert('Authentication failed')
                    return false;
                }

                this.setState({
                    apiload: false,
                });
                this.setToken(token)

            }

            const expired = await tokenExpired(code)

            console.log('session expired', expired)
            if (expired) {
                console.log('re-authenticate')
                this.setState({
                    apiload: true,
                });
                // const auth = await authenticate(code)
                const { success, token } = await authenticate(code)
                if (!success) {
                    // @todo : change with a nice UI
                    alert('Authentication failed')
                    return false;
                }
                this.setToken(token)
            }


            const fromback = (new URLSearchParams(window.location.search)).get("fromback")
            if (fromback != undefined) {
                console.log('fromback')
                this.setState({
                    landingPage: false,
                });
            }


            const qrStatus = await getQrStatus(code)
            const qrType = await getQrType(code)
            const qrTimeStamp = await getQrTimeStamp(code)

            const sameDay = moment(moment(qrTimeStamp).format('YYYY-MM-DD')).isSame(moment().format('YYYY-MM-DD'), 'day')

            console.log('app.qrType', qrType)
            console.log('app.qrTimeStamp', qrTimeStamp)
            console.log('DAY 1', moment(qrTimeStamp).format('YYYY-MM-DD'))
            console.log('DAY 2', moment().format('YYYY-MM-DD'))
            console.log('app.SAME DAY', sameDay)


            // for QR Dynamic, only valid on same day
            if (qrType == 'D'
                && !sameDay
            ) {
                alert('Sorry, something went wrong! The QR Code you are using is no longer valid, please contact the merchant staff.')
                return false;
            }

            // for QR Static, if suspende, block it
            if (qrType == 'S'
                && !qrStatus == 'S'
            ) {
                alert('Sorry, something went wrong! The QR Code you are using is no longer valid, please contact the merchant staff.')
                return false;
            }

            const currency = await getCurrency(code)
            const guest = await getGuest(code)
            const name = await getName(code)
            const mail = await getMail(code)
            const address = await getAddress(code)
            const phone = await getPhone(code)
            const headerImage = await getImg(code)
            const table = await getTable(code)

            console.log('APP.headerImage', headerImage)

            this.setState({
                appCurrency: currency,
                restoInfo: {
                    name: name,
                    address: address,
                    email: mail,
                    phone: phone,
                    table: table,
                    qrType: qrType,
                    guest: guest,
                    headerImage: headerImage,
                },
                apiload: true,
            });

            const chargeOptions = await getChargeOpt(code)
            console.log('app.chargeOptions', chargeOptions)
            console.log('app.chargeOptions.empty', chargeOptions == '')

            // if (chargeOptions == '' || chargeOptions == null) {
            //     alert('Merchant has no additional charges options. Please contact merchant admin.')
            //     return false;
            // }

            if (!!chargeOptions) {
                var appTaxOpt = []
                const taxOpt = JSON.parse(chargeOptions)
                for (var i in taxOpt) {
                    const opt = taxOpt[i]
                    // { name: 'SVC Tax', value: 10, type: 'percentage', include: false },
                    appTaxOpt.push({
                        name: opt.label,
                        value: parseFloat(opt.value),
                        type: 'percentage',
                        include: false
                    })
                }
                console.log('app.appTaxOpt', appTaxOpt)
                this.setState({
                    appTax: appTaxOpt
                })
            }

            // todo : move api call listProducts to home components
            const t = await getToken(code)
            const list = await listProducts(t, code)
            this.setState({
                // token: t,
                menuList: list,
                qrcode: code,
                apiload: false,
            });
            this.setToken(t)

            // // dummy
            // this.setState({
            //   // menuList: require('./../data.json'),
            //   menuList: require('./../data-api.json'),
            //   qrcode: code,
            // });

        }
    }

    componentDidUpdate(prevProps, prevState) {
        console.log('app.componentDidUpdate')
    }

    componentWillUnmount() { }

    addItem(index, categoryId, itemId, productId, qty, note, sku, custom, combo) {
        console.log('addItem.COMBO', combo)

        let allItem = [...this.state.menuList],
            listItem = allItem[categoryId].itemList,
            listAdd = [...this.state.addedList],
            selSku = '',
            iSku = '',
            theItem = listItem[itemId],
            theQty = theItem.added,
            // itemPrice = (theItem.price + custom.price + combo.price) * qty,
            // itemPrice = (theItem.price) * qty,
            itemPrice = 0,
            qtyTotal = this.state.totalQty,
            priceTotal = this.state.totalPrice,
            isCombo = combo.comboArray.length > 0
            ;


        if (isCombo) {
            itemPrice = (theItem.price) * qty
        } else {
            // itemPrice = (theItem.price + custom.price + combo.price) * qty
            itemPrice = (theItem.price + custom.price) * qty
        }

        console.log('addItem.itemId', itemId)
        console.log('addItem.productId', productId)
        console.log(theItem.price, custom.price, combo.price, qty);
        console.log('addItem.isCombo', isCombo)

        // const sameItem = listAdd.findIndex(x => x.itemId === itemId && x.note === note && x.skuId === sku);
        // const sameItem = listAdd.findIndex(x => x.itemId === itemId);
        const sameItem = listAdd.findIndex(x => x.productId === productId);

        console.log('addItem.sameItem', sameItem)


        // console.log('addItem.itemId', itemId)

        if (sameItem === -1) {
            if (sku !== '') {
                iSku = sku;
                selSku = theItem.sku[sku].name;
            }

            if (index === '') {
                theItem.added = theQty + qty;
                qtyTotal = qtyTotal + qty;
                priceTotal = priceTotal + itemPrice;

                listAdd.push({
                    cartIndex: listAdd.length,
                    itemId: itemId,
                    productId: productId,
                    name: theItem.name,
                    qty: qty,
                    note: note,
                    skuId: iSku,
                    sku: selSku,
                    custom: custom.customArray,
                    combo: combo.comboArray,
                    price: itemPrice
                });
            } else {
                theItem.added = theQty - listAdd[index].qty + qty;
                qtyTotal = qtyTotal - listAdd[index].qty + qty;
                priceTotal = priceTotal - listAdd[index].price + itemPrice;

                if (qty) {
                    listAdd[index].qty = qty;
                    listAdd[index].price = itemPrice;
                    listAdd[index].note = note;
                    listAdd[index].skuId = iSku;
                    listAdd[index].sku = selSku;
                } else {
                    listAdd.splice(index, 1);
                }
            }
        } else {
            listAdd[sameItem].qty = listAdd[sameItem].qty + qty;
            listAdd[sameItem].price = listAdd[sameItem].price + itemPrice;

            if (index !== '') {
                theItem.added = theQty - listAdd[index].qty + qty;
                qtyTotal = qtyTotal - listAdd[index].qty + qty;
                priceTotal = priceTotal - listAdd[index].price + itemPrice;

                listAdd.splice(index, 1);
            } else {
                theItem.added = theQty + qty;
                qtyTotal = qtyTotal + qty;
                priceTotal = priceTotal + itemPrice;
            }
        }

        if ((note !== '' && theItem.note === false) || (note === '' && theItem.note === true)) {
            theItem.note = false;

            listAdd.forEach((value, index) => {
                if (value.itemId === itemId && value.note) {
                    theItem.note = true;

                    return true;
                }
            });
        }

        this.setState({
            menuList: allItem,
            addedList: listAdd,
            totalQty: qtyTotal,
            totalPrice: priceTotal,
        });

        console.log(listAdd);
    }

    addOrderType(e) {
        console.log('addOrderType')
        this.setOrderType(e.currentTarget.value)
        this.setState({
            landingPage: false,
        })
    }

    setFromBack(fromback) {
        // console.log('addOrderType')
        // this.setOrderType(e.currentTarget.value)
        // this.setState({
        //     landingPage: false,
        // })
        // const fromback = (new URLSearchParams(window.location.search)).get("fromback")
        // if(fromback != undefined){
        //     console.log('fromback')
        this.setState({
            landingPage: fromback ? false : true,
        });
        // }

    }

    addPayment(code, phone, method, status, priceSum, grandTotal, discPromo) {
        if (status) {
            let theCheckout = [...this.state.checkedOut],
                lengthCheckout = theCheckout.length + 1;

            theCheckout.push({
                id: 'STG-SC-00000000' + lengthCheckout,
                queue: lengthCheckout,
                code: code,
                phone: phone,
                method: method,
                listItem: this.state.addedList,
                total: this.state.totalPrice,
                priceSum: priceSum,
                grandTotal: grandTotal,
                discPromo: discPromo,
                time: new Date()
            });

            this.setState({
                paymentStatus: status,
                infoCode: code,
                infoPhone: phone,
                paymentMethod: '',
                checkedOut: theCheckout,
                addedList: [],
                totalQty: 0,
                totalPrice: 0,
                // menuList: JSON.parse(JSON.stringify(theData)),
                priceSum: '',
                grandTotal: 0,
                discPromo: ''
            });
        } else {
            this.setState({
                paymentStatus: status,
                infoCode: code,
                infoPhone: phone,
                paymentMethod: method,
                priceSum: priceSum,
                grandTotal: grandTotal,
                discPromo: discPromo
            });
        }
    }

    render() {
        // if (this.state.error.error) return <Navigate to="/error" />
        return (
            <Sentry.ErrorBoundary fallback={myFallback} showDialog>
                <MainContext.Provider value={this.state}>
                    <Helmet>
                        <title>Welcome to Spurr Cafe</title>
                    </Helmet>

                    <Suspense fallback={
                        <SplashScreen splashText="Please Wait" />
                    }>
                        <Routes>
                            <Route path="/" element={
                                <Home
                                    appCurrency={this.state.appCurrency}
                                    appRequest={this.appRequest}
                                    restoInfo={this.state.restoInfo}
                                    menuList={this.state.menuList}
                                    addItem={this.addItem}
                                    addedList={this.state.addedList}
                                    totalQty={this.state.totalQty}
                                    totalPrice={this.state.totalPrice}
                                    checkedOut={this.state.checkedOut}
                                    qrcode={this.state.qrcode}
                                    token={this.state.token}
                                    orderCount={this.state.orderCount}
                                    apiload={this.state.apiload}
                                    landingPage={this.state.landingPage}
                                    addOrderType={this.addOrderType}
                                    setFromBack={this.setFromBack}
                                />
                            } exact />
                            <Route path="/search" element={
                                this.state.menuList.length ? (
                                    <Search
                                        appCurrency={this.state.appCurrency}
                                        appRequest={this.appRequest} menuList={this.state.menuList} />
                                ) : (
                                    <Navigate to="/" />
                                )
                            } />
                            <Route path="/combo/*" element={
                                this.state.menuList.length ? (
                                    <Combo
                                        appCurrency={this.state.appCurrency}
                                        appRequest={this.appRequest}
                                        menuList={this.state.menuList}
                                        qrcode={this.state.qrcode}
                                        addItem={this.addItem}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            } />
                            <Route path="/cart" element={
                                this.state.menuList.length && this.state.addedList.length ? (
                                    <Cart
                                        appCurrency={this.state.appCurrency}
                                        appRequest={this.appRequest}
                                        appTax={this.state.appTax}
                                        restoInfo={this.state.restoInfo}
                                        paymentMethod={this.paymentMethod}
                                        addPayment={this.addPayment}
                                        addItem={this.addItem}
                                        addedList={this.state.addedList}
                                        totalPrice={this.state.totalPrice}
                                        appPromo={this.appPromo}
                                        countryCode={this.countryCode}
                                        defaultCode={this.defaultCode}
                                        infoCode={this.state.infoCode}
                                        infoPhone={this.state.infoPhone}
                                        qrcode={this.state.qrcode}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            } />
                            <Route path="/result" element={
                                (this.state.menuList.length && this.state.checkedOut.length && this.state.paymentStatus) || (this.state.menuList.length && this.state.addedList.length && !this.state.paymentStatus) ? (
                                    <Result
                                        appCurrency={this.state.appCurrency}
                                        appRequest={this.appRequest}
                                        restoInfo={this.state.restoInfo}
                                        paymentMethod={this.state.paymentMethod}
                                        paymentStatus={this.state.paymentStatus}
                                        addedList={this.state.addedList}
                                        checkedOut={this.state.checkedOut}
                                        totalPrice={this.state.totalPrice}
                                        priceSum={this.state.priceSum}
                                        grandTotal={this.state.grandTotal}
                                        infoCode={this.state.infoCode}
                                        infoPhone={this.state.infoPhone}
                                        qrcode={this.state.qrcode}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            } />
                            {/* <Route path="/order" element={
                this.state.menuList.length && this.state.checkedOut.length ? (
                  <Order
                    appCurrency={this.state.appCurrency}
                    appRequest={this.appRequest}
                    restoInfo={this.state.restoInfo}
                    checkedOut={this.state.checkedOut}
                    qrcode={this.state.qrcode}
                  />
                ) : (
                  <Navigate to="/" />
                )
              } /> */}
                            <Route path="/order" element={
                                this.state.menuList.length ? (
                                    <Order
                                        appCurrency={this.state.appCurrency}
                                        appRequest={this.appRequest}
                                        restoInfo={this.state.restoInfo}
                                        checkedOut={this.state.checkedOut}
                                        qrcode={this.state.qrcode}
                                    />
                                ) : (
                                    <Navigate to={"/"} />
                                )
                            } />
                            <Route path="/order-detail" element={
                                this.state.menuList.length ? (
                                    <OrderDetail
                                        appCurrency={this.state.appCurrency}
                                        appRequest={this.appRequest}
                                        restoInfo={this.state.restoInfo}
                                        checkedOut={this.state.checkedOut}
                                        qrcode={this.state.qrcode}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            } />
                            <Route path="/bill" element={
                                this.state.menuList.length && this.state.checkedOut.length ? (
                                    <Result
                                        appCurrency={this.state.appCurrency}
                                        appRequest={this.appRequest}
                                        restoInfo={this.state.restoInfo}
                                        checkedOut={this.state.checkedOut}
                                        qrcode={this.state.qrcode}
                                    />
                                ) : (
                                    <Navigate to="/" />
                                )
                            } />
                            <Route path="/error" element={
                                <Error
                                    restoInfo={this.state.restoInfo}
                                    qrcode={this.state.qrcode}
                                    error={this.state.error}
                                />
                            } />
                            <Route path="*" element={<Navigate to="/" />} />
                        </Routes>
                    </Suspense>
                </MainContext.Provider>
            </Sentry.ErrorBoundary>
        );
    }
}

export default App;
