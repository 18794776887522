import axios from "axios";

const client = axios.create({
    baseURL: `http://spurr-api-dev.gospurr.com/qrorder`,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json'
    }
  });



export default client
