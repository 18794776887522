import FingerprintJS from '@fingerprintjs/fingerprintjs'
import client from '../client'
const fpPromise = FingerprintJS.load()

/**
 * setAuth
 * @param {*} code
 * @param {*} session
 */
function setExp(code, expvalue) {
    const name = 'tg-exp-' + code.replace(/-/g, '');
    localStorage.setItem(name, expvalue)
}

function getExp(name) {
    return localStorage.getItem(name)
}

/**
 * setAuth
 * @param {*} code
 * @param {*} session
 */
function setAuth(code, session) {
    const name = 'tg-' + code.replace(/-/g, '');
    localStorage.setItem(name, session)
}

function getAuth(name) {
    return localStorage.getItem(name)
}

/**
 * setMeta
 * @param {*} code
 * @param {*} session
 */
function setMeta(code, meta) {
    localStorage.setItem('tg-guest-' + code.replace(/-/g, ''), meta['qrcodeGuest'])
    localStorage.setItem('tg-qr-id-' + code.replace(/-/g, ''), meta['qrcodeId'])
    localStorage.setItem('tg-qr-type-' + code.replace(/-/g, ''), meta['qrcodeType'])
    localStorage.setItem('tg-qr-status-' + code.replace(/-/g, ''), meta['qrcodeStatus'])
    localStorage.setItem('tg-qr-ts-' + code.replace(/-/g, ''), meta['qrcodeTimestamp'])
    localStorage.setItem('tg-cur-' + code.replace(/-/g, ''), meta['merchantCurrency'])
    localStorage.setItem('tg-name-' + code.replace(/-/g, ''), meta['merchantName'])
    localStorage.setItem('tg-addr-' + code.replace(/-/g, ''), meta['merchantAddress'])
    localStorage.setItem('tg-mail-' + code.replace(/-/g, ''), meta['merchantEmail'])
    localStorage.setItem('tg-phone-' + code.replace(/-/g, ''), meta['merchantPhone'])
    localStorage.setItem('tg-img-' + code.replace(/-/g, ''), meta['merchantImage'])
    localStorage.setItem('tg-charge-' + code.replace(/-/g, ''), meta['merchantAdditionalCharges'])
    localStorage.setItem('tg-table-' + code.replace(/-/g, ''), meta['tableNumber'])
}

function getItem(name) {
    return localStorage.getItem(name)
}

/**
 * authenticate
 * @param {*} code
 * @returns
 */
export const authenticate = async (code) => {
    try {
        const fp = await fpPromise
        const result = await fp.get()
        const requstPayload = {
            'module': 'QrOrder',
            'action': 'token',
            'payload': {
                'fingerprint': result.visitorId,
                'code': code
            }
        }
        const request = await client.post(`${process.env.REACT_APP_API_URL}/qrorder/`, requstPayload)
        const { data } = request
        const { payload } = data

        setAuth(code, payload['sessionValue'])

        setMeta(code, payload['meta'])

        const minutesToAdd = 8;
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
        setExp(code, futureDate)
        // return true
        return {
            success: true,
            token: payload['sessionValue']
        }
    } catch (e) {
        // return false
        return {
            success: false,
        }
    }
}

/**
 * authenticate
 * @param {*} code
 * @returns
 */
export const reAuthenticate = async (code) => {
    try {
        const fp = await fpPromise
        const result = await fp.get()
        const requstPayload = {
            'module': 'QrOrder',
            'action': 'token',
            'payload': {
                'fingerprint': result.visitorId,
                'code': code
            }
        }
        const request = await client.post(`${process.env.REACT_APP_API_URL}/qrorder/`, requstPayload)
        const { data } = request
        const { payload } = data

        setAuth(code, payload['sessionValue'])

        setMeta(code, payload['meta'])

        const minutesToAdd = 8;
        const currentDate = new Date();
        const futureDate = new Date(currentDate.getTime() + minutesToAdd * 60000);
        setExp(code, futureDate)
        return {
            success: true,
            token: payload['sessionValue'],
        }
    } catch (e) {
        return {
            success: false,
            token: null,
        }
    }
}

/**
 * authenticated
 * @param {*} code
 * @returns
 */
export const authenticated = async (code) => {
    const a = getAuth('tg-' + code)
    // console.log('A', a)
    return !!a
}

/**
 * get token
 * @param {*} code
 * @returns
 */
export const getToken = async (code) => {
    return getAuth('tg-' + code)
}

/**
 * get currency
 * @param {*} code
 * @returns
 */
export const getCurrency = async (code) => {
    return getItem('tg-cur-' + code)
}

/**
 * get qr id
 * @param {*} code
 * @returns
 */
export const getQrId = (code) => {
    return getItem('tg-qr-id-' + code.replace(/-/g, ''))
}

/**
 * get qr type
 * @param {*} code
 * @returns
 */
export const getQrType = (code) => {
    return getItem('tg-qr-type-' + code.replace(/-/g, ''))
}

/**
 * get qr status
 * @param {*} code
 * @returns
 */
export const getQrStatus = (code) => {
    return getItem('tg-qr-status-' + code.replace(/-/g, ''))
}

/**
 * get qr TimeStamp
 * @param {*} code
 * @returns
 */
export const getQrTimeStamp = (code) => {
    return getItem('tg-qr-ts-' + code.replace(/-/g, ''))
}

/**
 * get guest
 * @param {*} code
 * @returns
 */
export const getGuest = async (code) => {
    return getItem('tg-guest-' + code)
}

/**
 * get table
 * @param {*} code
 * @returns
 */
export const getTable = async (code) => {
    return getItem('tg-table-' + code)
}

/**
 * get name
 * @param {*} code
 * @returns
 */
export const getName = async (code) => {
    return getItem('tg-name-' + code)
}

/**
 * getChargeOpt
 * @param {*} code
 * @returns
 */
export const getChargeOpt = async (code) => {
    return getItem('tg-charge-' + code)
}

/**
 * get mail
 * @param {*} code
 * @returns
 */
export const getMail = async (code) => {
    return getItem('tg-mail-' + code)
}

/**
 * get address
 * @param {*} code
 * @returns
 */
export const getAddress = async (code) => {
    return getItem('tg-addr-' + code)
}

/**
 * get phone
 * @param {*} code
 * @returns
 */
export const getPhone = async (code) => {
    return getItem('tg-phone-' + code)
}

/**
 * get qr type
 * @param {*} code
 * @returns
 */
export const getImg = (code) => {
    return getItem('tg-img-' + code.replace(/-/g, ''))
}


/**
 * authenticated
 * @param {*} code
 * @returns
 */
export const tokenExpired = async (code) => {
    const a = getExp('tg-exp-' + code)
    if (a == undefined) {
        return true
    }
    return new Date() > new Date(a)
}
