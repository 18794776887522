import React from 'react';

// const defaultVal = { profile: {} } //Insert the default value here.
const defaultVal = {
    backTo: '',
    code: null,
    token: null,
    orderNumber: null,
    queueNumber: null,
    orderCount: 1,
    orderType: 1,
    order: {}
} //Insert the default value here.
export const MainContext = React.createContext(defaultVal);
