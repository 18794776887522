import client from "../client"
import { authenticate, authenticated, reAuthenticate, getToken } from "../../api/services/auth"

export const listProducts = async (t, code) => {
    try {
        const requstPayload = {
            "module": "QrOrder",
            "action": "list",
            "token": t,
            "payload": {}
        }
        const request = await client.post(`${process.env.REACT_APP_API_URL}/qrorder/`, requstPayload)
        const { data } = request
        const { errorCode } = data
        if (errorCode == 0x1104) {
            console.log('re-authentice')
            const { success, token } = await reAuthenticate(code)
            if (!success) {
                alert('Authentication failed')
                return false;
            }
            // t = getToken(code)
            t = token
            return await listProducts(t, code)
        }
        const { payload } = data
        return payload
    } catch (e) {
        return []
    }
}

export const getProduct = (request) => {
    return client.post('http://spurr-api-dev.gospurr.com/qrorder/', request);
}

export const insertProduct = (request) => {
    return client.post('http://spurr-api-dev.gospurr.com/qrorder/', request);
}

export const updateProduct = (request) => {
    return client.post('http://spurr-api-dev.gospurr.com/qrorder/', request);
}

export const deleteProduct = (request) => {
    return client.post('http://spurr-api-dev.gospurr.com/qrorder/', request);
}

export const example = async (request) => {
    return (new Promise((resolve, reject) => {
        setTimeout(() => {
            resolve(require('../../data.json'));
        }, 300);
    }))
}
