import React, { Component } from 'react';
import logoResto from './../../assets/img/logo-resto.svg';
import logoSpurr from './../../assets/img/logo-white.svg';

class SplashScreen extends Component {
  constructor() {
    super();

    this.state = {
      // multiList: []
    }

    // this.openPop = this.openPop.bind(this);
  }

  componentDidMount() { }

  componentDidUpdate(prevProps, prevState) {
    // if(this.state.open !== prevState.open){}
  }

  componentWillUnmount() { }

  render() {
    return (
      <div className="splashscreen">
        <div className="splashwrap">
          <img src={logoResto} alt="logo Resto" />
          <span>{this.props.splashText}...</span>
        </div>
        <div className="powered">
          Powered by<img src={logoSpurr} alt="Logo Spurr" />
        </div>
      </div>
    );
  }
}

export default SplashScreen;